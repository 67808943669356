import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {fetchApi} from '../fetchApi/fetchApi';
import Bg01 from '../assets/img/bg-02.jpg';
import Spinner from '../components/Spinner';

const Productos = () => {
    const [info, setInfo] = useState([]);
    const [showSpiner, setShowSpiner] = useState(true);

	useEffect(() => {
		fetchApi('items/products', 'GET')
			.then(response => {
                setInfo(response);
                setShowSpiner(false);
			});
        }, []);
    console.log(info);
    return (
        <div className="p-b-120">
            <section className="bg-img1 txt-center p-lr-15 p-tb-92 m-b-40" style={{ 'backgroundImage': `url(${Bg01})` }}>
                <h2 className="ltext-105 cl0 txt-center m-b-0">Productos</h2>
            </section>
            <div className="sec-banner bg0 p-t-40'p-b-50">
                {showSpiner ?
                    <Spinner />
                : null}
                <div className="container">
                    <div className="row">
                        {info.map((item, index) => {
                            const {title, img, img_url} = item;
                            return( 
                                <div style={{cursor:'pointer ! important'}} className="col-md-6 col-xl-3 p-b-30 m-lr-auto" key={index}>
                                    <Link to={img_url}>
                                        <div className="block1 wrap-pic-w">
                                            <img src={img} alt="IMG-BANNER" />
                                            <div className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3">
                                                <div className="block1-txt-child1 flex-col-l">
                                                    <span className="block1-name ltext-102 trans-04 p-b-8">
                                                        {title}
                                                    </span>
                                                </div>
                                                <div className="block1-txt-child2 p-b-4 trans-05">
                                                    <div className="block1-link stext-101 cl0 trans-09">
                                                        Detalles           
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                        <div className="flex-c-m flex-w w-full p-t-45">
                            
                            <a href="https://laperla.lttedev.com.mx.mx/assets/Catalogo_LA PERLA_2020.pdf" className="flex-c-m stext-101 cl5 size-103 blue_b bor1 hov-btn1 p-lr-15 btn_prods btn_cat_b trans-04" download> Catálogo Completo <i className="fa fa-file-pdf-o catalog_c"></i></a>
                            <Link to="/tienda" className="flex-c-m stext-101 cl5 size-103 blue_b bor1 hov-btn1 p-lr-15 btn_prods btn_cat_b trans-04">
                                Catálogo <i className="fa fa-file-pdf-o catalog_c"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Productos;