import * as React from "react";
import { Link } from 'react-router-dom';
import { fetchApi } from "../../fetchApi/fetchApi";

const Footer: React.FC = () => {
    const [whatsapp, setWhatsapp] = React.useState([]);
    React.useEffect(() => {
        fetchApi('whatsapp', 'GET')
            .then(response => {
                setWhatsapp(response[0].value);
            });
    }, [])

    return (
        <footer className="bg1 p-t-75 p-b-32">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-lg-3 p-b-50">
                        <h4 className="stext-301 cl0 p-b-30">
                            Productos
                            </h4>

                        <ul>
                            <li className="p-b-10">
                                <Link to="/saborizantes" className="stext-107 cl7 hov-cl1 trans-04">
                                    Saborizantes
                                    </Link>
                            </li>
                            <li className="p-b-10">
                                <Link to="/colorantes" className="stext-107 cl7 hov-cl1 trans-04">
                                    Colorantes
                                    </Link>
                            </li>

                            <li className="p-b-10">
                                <Link to="/materiasprimas" className="stext-107 cl7 hov-cl1 trans-04">
                                    Materias Primas
                                    </Link>
                            </li>

                            <li className="p-b-10">
                                <Link to="/moldes" className="stext-107 cl7 hov-cl1 trans-04">
                                    Moldes
                                    </Link>
                            </li>
                            <li className="p-b-10">
                                <Link to="/charolas" className="stext-107 cl7 hov-cl1 trans-04">
                                    Charolas
                                    </Link>
                            </li>
                            <li className="p-b-10">
                                <Link to="/utensilios" className="stext-107 cl7 hov-cl1 trans-04">
                                    Utensilios
                                    </Link>
                            </li>
                            <li className="p-b-10">
                                <Link to="/decoracion" className="stext-107 cl7 hov-cl1 trans-04">
                                    Decoracion
                                    </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-sm-6 col-lg-3 p-b-50">
                        <h4 className="stext-301 cl0 p-b-30">
                            Acerca
                            </h4>

                        <ul>
                            <li className="p-b-10">
                                <Link to="/nosotros" className="stext-107 cl7 hov-cl1 trans-04">
                                    Nosotros
                                    </Link>
                            </li>

                            <li className="p-b-10">
                                <Link to="/distribuidores" className="stext-107 cl7 hov-cl1 trans-04">
                                    Distribuidores
                                    </Link>
                            </li>

                            <li className="p-b-10">
                                <Link to="/novedades" className="stext-107 cl7 hov-cl1 trans-04">
                                    Novedades
                                    </Link>
                            </li>

                            <li className="p-b-10">
                                <Link to="/contacto" className="stext-107 cl7 hov-cl1 trans-04">
                                    Contacto
                                    </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-sm-6 col-lg-3 p-b-50">
                        <h4 className="stext-301 cl0 p-b-30">
                            CONTACTO
                            </h4>

                        <p className="stext-107 cl7 size-201">
                            TEL. (55) 5341 6767  o  01 800 234 6767
                            </p>

                    </div>

                    <div className="col-sm-6 col-lg-3 p-b-50">
                        <h4 className="stext-301 cl0 p-b-30">
                            Redes Sociales
                            </h4>
                        <div>
                            <a href="https://www.facebook.com/rafmexoficial/" rel="noopener noreferrer" target="_blank" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                                <i className="fa fa-facebook"></i>
                            </a>
                            <a href="https://www.instagram.com/rafmexoficial/" rel="noopener noreferrer" target="_blank" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                                <i className="fa fa-instagram"></i>
                            </a>
                            <a href={'https://api.whatsapp.com/send?phone=' + whatsapp} rel="noopener noreferrer" target="_blank" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                                <i className="fa fa-whatsapp"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div>


                    <p className="stext-107 cl6 txt-center">
                        LA PERLA S.A. DE C.V.<br />
                        <a href="https://laperla.lttedev.com.mx.mx/assets/aviso.pdf" rel="noopener noreferrer" target="_blank">Aviso de Privacidad</a>
                    </p>

                </div>
            </div>
        </footer>
    );
}

export default Footer;
