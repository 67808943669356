export const fetchApi = async (url, method) => {
	let instance = '';
	try {
		if (method && method === 'GET') {
			instance = await fetch( `https://laperla.back-end.com.mx/api/v1/GET/${url}`, {
				method,
				headers: {
					apikey: '45e3d86c-4a3e-4309-94f6-6a7ab27e7217'
				}
			});
		}
        
		if (instance.status === 200) {
			const response = await instance.json();
			let { value } = response;
			return await value;
		} else {
			return await instance;
		}
	} catch (error) {
		throw new Error(`😱 ${error}`);
	}
};

export const fetchApiPost = async (url, method, body) => {
	let instance = '';
	try {
		if (method && method === 'POST') {
			instance = await fetch(`https://laperla.back-end.com.mx/api/v1/CREATE/${url}`, {
				method,
				'headers': {
					apikey: '45e3d86c-4a3e-4309-94f6-6a7ab27e7217'
				},
				'body': body
			});
		}

		if (instance.status === 200) {
			const response = await instance.json();
			let { value } = response;
			return await value;
		} else {
			return await instance;
		}
	} catch (error) {
		throw new Error(`😱 ${error}`);
	}
};

export const fetchPreferenceId = async (items) => {
	let instance = '';
	try {
		instance = await fetch(`https://rafmexpagos.back-end.com.mx/getPreferenceId/`, {
			method:'POST',
			body: items
		});

		if (instance.status === 200) {
			const response = await instance.json();
			return await response.message;
		} else {
			return await instance;
		}
	} catch (error) {
		throw new Error(`😱 ${error}`);
	}
};

export const fetchQuotation = async (address_to) => {
	let instance = '';

	var testBody = JSON.stringify({
		"address_to": address_to
	});


	try {
		instance = await fetch(`https://rafmexpagos.back-end.com.mx/getQuotations/`, {
			method: 'POST',
			body: testBody
		});

		console.log(instance)

		if (instance.status === 200) {
			const response = await instance.json();
			return await response.message;
		} else {
			return await instance;
		}
	} catch (error) {
		throw new Error(`😱 ${error}`);
	}
}

