import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { fetchApi } from '../fetchApi/fetchApi';
import Spinner from '../components/Spinner';

const SupplierModal: React.FC<{ title:any, show: any, handleClose: any }> = (props) => {

    const [suppliers, setSuppliers] = useState([{ 'name': '', 'address': '', 'phone': '', 'mail': '', 'web': '' }])
    const [loading, setLoading] = useState(true)

    const getSuppliers = (state: any) => {
        setLoading(true)
        fetchApi('state/' + state, 'GET')
            .then(response => {
                console.log(response)
                setSuppliers(response)
                setLoading(false)
            });
    }

    useEffect(() => {
        getSuppliers(props.title)
    }, [props.title]);
    
    return (
        <Modal show={props.show} onHide={props.handleClose} centered size="lg" animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading ? 
                    suppliers === null ?
                        (
                            <div>
                                <span className="mtext-106 cl2">
                                    LA PERLA S.A. DE C.V.
                                </span>
                                <p className="stext-102 cl3 p-t-10">
                                    Aquí hay un lugar para contactarnos.
                                </p>
                                <p className="stext-102 cl3 p-t-10">
                                    San Esteban No. 58, Col. Santo Tomás, C.P.02020, Azcapotzalco, CDMX TEL.: 55 53416767 y 01 800 234 6767
                                </p>
                                <a href={'mailto:vts@laperla.lttedev.com.mx'}>vts@laperla.lttedev.com.mx</a>
                            </div>
                        )
                        :
                        (
                            suppliers.map((item: any, index: any) => {
                                return (
                                    <div className="m-b-40" key={index}>
                                        <span className="mtext-106 cl2">
                                            {item.name}
                                        </span>
                                        <p className="stext-102 cl3 p-t-10">
                                            {item.address}
                                        </p>
                                        {item.email !== '' &&
                                            (
                                                <div>
                                                    <a rel="noopener noreferrer" href={`mailto:${item.email}`}>{item.email}</a>
                                                </div>
                                            )}
                                        {item.site !== '' &&
                                            (
                                                <div>
                                                    <a target="_blank" rel="noopener noreferrer" href={'http://' + item.site}>{item.site}</a>
                                                </div>
                                            )}
                                    </div>
                                )
                            })
                        )
                :
                    (
                        <Spinner></Spinner>
                    )
                }
                
            </Modal.Body>
        </Modal>
    )
};
export default SupplierModal;