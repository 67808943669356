import React, { useEffect, useState } from 'react';
import {fetchApi} from '../fetchApi/fetchApi';
import About01 from '../assets/img/about-01.jpg';
import About02 from '../assets/img/about-02.jpg';
import Bg01 from '../assets/img/bg-01.jpg';

const Nosotros = (props: any) => {
	const [mision, setMision] = useState('')
	const [vision, setVision] = useState('')
	const [valores, setValores] = useState('')

	useEffect(() => {
		fetchApi('identidad_empresa', 'GET')
		.then(response => {
			setMision(response[0].value)
			setVision(response[1].value)
			setValores(response[2].value)
		})
	},[]);

    return(
		<section className="bg0 p-b-120">
			<section className="bg-img1 txt-center p-lr-15 p-tb-92 m-b-80" style={{ 'backgroundImage': `url(${Bg01})` }}>
				<h2 className="ltext-105 cl0 txt-center m-b-0">Nosotros</h2>
			</section>
			<div className="container">
				<div className="row p-b-148">
					<div className="col-md-7 col-lg-8">
						<div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
							<h3 className="mtext-111 cl2 p-b-16">
								Misión
							</h3>
							<p className="stext-113 cl6 p-b-26">
								{mision}
							</p>
							<h3 className="mtext-111 cl2 p-b-16">
								Visión
							</h3>

							<p className="stext-113 cl6 p-b-26">
								{vision}
							</p>

						</div>
					</div>

					<div className="col-11 col-md-5 col-lg-4 m-lr-auto">
						<div className="how-bor1 ">
							<div className="hov-img0">
								<img src={About01} alt="IMG" />
							</div>
						</div>
					</div>
				</div>
				
				<div className="row">
					<div className="order-md-2 col-md-7 col-lg-8 p-b-30">
						<div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
							<h3 className="mtext-111 cl2 p-b-16">
								Valores
							</h3>

							<div className="stext-113 cl6 p-b-26 about_text">
								{valores}
							</div>

							<div className="bor16 p-l-29 p-b-9 m-t-22">
								<p className="stext-114 cl6 p-r-40 p-b-11">
									Somos socios de ANPROPAN y colaboradores de la CANAINPA.
								</p>

								<span className="stext-111 cl8">
									- LA PERLA
								</span>
							</div>
						</div>
					</div>

					<div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
						<div className="how-bor2">
							<div className="hov-img0">
								<img src={About02} alt="IMG" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>	
    )
};
export default Nosotros;